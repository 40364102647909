<script>
import ModuleNavigationBar from '@/components/Modules/ModuleNavigationBar.vue';
import { mapGetters } from 'vuex';
import moment from 'moment';
import AntInput from '@/components/AntInput.vue';
import InputRulesMixin from '@/Mixins/InputRulesMixin';
import { calculateProductionPlanning } from '@/modules/daiwa-house-modular-europe/ProductionPlanning/dhme-production-planning.utils';
import DhmeProductionPlanningChart from '@/modules/daiwa-house-modular-europe/ProductionPlanning/DhmeProductionPlanningChart.vue';

export default {
  name: 'DhmeProductionPlanning',
  components: {
    DhmeProductionPlanningChart,
    AntInput,
    ModuleNavigationBar,
  },
  mixins: [InputRulesMixin],
  data: () => {
    return {
      selectedProductionHallId: null,
      selectedProductionConfiguration: null,
      selectedProductionLane: null,
      startDate: null,
      startHour: null,
      workHours: null,
      dateMenu: false,
      elementTypeWorkloads: {},
      ganttScale: 3,
      ganttZoomLevels: [
        {
          key: 'year',
          value: 'Yearly',
        },
        {
          key: 'quarter',
          value: 'Quarter',
        },
        {
          key: 'month',
          value: 'Monthly',
        },
        {
          key: 'week',
          value: 'Weekly',
        },
        {
          key: 'day',
          value: 'Daily',
        },
        {
          key: 'hour',
          value: 'Hourly',
        },
      ],
      dateTab: 'date',
      previousAlignedAssemblyPhase: null,
      generatingPlanning: false,
      totalLength: 0,
    };
  },
  computed: {
    ...mapGetters({
      project: 'project',
      requestedTasks: 'dhmeProductionPlanningStore/requestedProductionTasks',
      plannedTasks: 'dhmeProductionPlanningStore/plannedProductionTasks',
      focusedTask: 'dhmeProductionPlanningStore/focusedTask',
      focusedLane: 'dhmeProductionPlanningStore/focusedLane',
      productionHalls: 'dhmeProductionPlanningStore/productionHalls',
      productionConfigurations:
        'dhmeProductionPlanningStore/productionConfigurations',
      productionLocations: 'dhmeProductionPlanningStore/productionLocations',
      focusedTaskAssemblyTasks:
        'dhmeProductionPlanningStore/focusedTaskAssemblyTasks',
      focusedElements: 'dhmeProductionPlanningStore/focusedTaskElements',
      focusedTaskElementTypes:
        'dhmeProductionPlanningStore/focusedTaskElementTypes',
      vacationDays: 'dhmeProductionPlanningStore/vacationCalendar',
      isLoading: 'dhmeProductionPlanningStore/isLoading',
      projectElementAssemblyTasks:
        'dhmeProductionPlanningStore/projectElementAssemblyTasks',
      importCounter: 'dhmeProductionPlanningStore/importCounter',
    }),

    configurationsByHall() {
      return this.productionConfigurations.filter(
        (c) => c.assembly_hall === this.selectedProductionHallId
      );
    },
    configurationLocations() {
      return this.productionLocations
        .filter((l) => l.configuration === this.selectedProductionConfiguration)
        .sort((a, b) => parseInt(a.assembly_lane) - parseInt(b.assembly_lane));
    },
    now() {
      return moment().format('YYYY-MM-DD');
    },
    configurationPhases() {
      return this.plannedTasks.filter(
        (t) =>
          t.task_type.custom_4 === this.selectedProductionConfiguration &&
          t.task_type.custom_7 === this.selectedProductionLane
      );
    },
  },
  watch: {
    focusedTask(value) {
      if (value) {
        this.startDate = value.planned_start
          ? moment(value.planned_start).format('YYYY-MM-DD')
          : moment().format('YYYY-MM-DD');
        this.selectedProductionHallId = value.task_type.custom_3;
        this.selectedProductionConfiguration = value.task_type.custom_4;
        this.startHour = value.task_type.custom_5;
        this.workHours = value.task_type.custom_6;
        this.selectedProductionLane = parseInt(value.task_type.custom_7);

        const metadata = JSON.parse(value.task_type.metadata);
        this.elementTypeWorkloads = metadata?.elementTypeWorkloads ?? {};
        this.$store.dispatch(
          'dhmeProductionPlanningStore/fetchElementsInGroupInOrder'
        );
      }
    },
    ganttScale(value) {
      this.$refs['production-hall-gantt'].setZoomLevel(
        this.ganttZoomLevels[value].key
      );
    },
    project: {
      immediate: true,
      handler(newValue) {
        if (newValue && newValue.is_master) {
          this.$store.dispatch(
            'dhmeProductionPlanningStore/fetchProductionTasks'
          );
          this.$store.dispatch(
            'dhmeProductionPlanningStore/fetchProductionHallsAndConfigurations'
          );
        }
      },
    },
  },
  beforeDestroy() {
    this.closePlanner();
  },
  methods: {
    formatTimestamp(timestamp) {
      return moment(timestamp).format('DD:MM:YYYY HH:MM:ss');
    },
    focusOnTask(task) {
      if (task.id !== this.focusedTask?.id) {
        this.$store.commit('dhmeProductionPlanningStore/focus_on_task', task);
      } else {
        this.$store.commit('dhmeProductionPlanningStore/focus_on_task', null);
      }
    },
    countElementsByType(type) {
      return this.focusedElements.filter((e) => e.element_type === type).length;
    },
    closePlanner() {
      this.selectedProductionHallId = null;
      this.selectedProductionConfiguration = null;
      this.selectedProductionLane = null;
      this.startDate = null;
      this.startHour = null;
      this.workHours = null;
      this.dateMenu = false;
      this.elementTypeWorkloads = {};
      this.$refs?.productionPlanner?.reset();
      this.$store.commit('dhmeProductionPlanningStore/focus_on_task', null);
    },
    async planProduction() {
      if (this.$refs.productionPlanner.validate()) {
        this.generatingPlanning = true;

        if (this.previousAlignedAssemblyPhase) {
          this.startHour = this.previousAlignedAssemblyPhase.task_type.custom_5;
          this.workHours = this.previousAlignedAssemblyPhase.task_type.custom_6;
        }

        const tasks = await calculateProductionPlanning(
          [...this.configurationLocations],
          parseInt(this.startHour),
          parseInt(this.workHours),
          this.startDate,
          this.focusedTaskAssemblyTasks,
          this.focusedElements,
          this.elementTypeWorkloads,
          this.selectedProductionLane,
          this.focusedTask,
          this.selectedProductionHallId,
          this.projectElementAssemblyTasks,
          this.vacationDays,
          this.previousAlignedAssemblyPhase ?? null
        );

        this.totalLength = tasks.length;

        tasks.unshift({
          id: this.focusedTask.id,
          planned_start: moment
            .min(tasks.map((t) => moment(t.planned_start)))
            .format('YYYY-MM-DD HH:mm:ss'),
          planned_end: moment
            .max(tasks.map((t) => moment(t.planned_end)))
            .format('YYYY-MM-DD HH:mm:ss'),
          due: moment
            .max(tasks.map((t) => moment(t.planned_end)))
            .format('YYYY-MM-DD HH:mm:ss'),
          options: {
            custom_1: 'planned',
            custom_3: this.selectedProductionHallId,
            custom_4: this.selectedProductionConfiguration,
            custom_5: this.startHour,
            custom_6: this.workHours,
            custom_7: this.selectedProductionLane,
            metadata: JSON.stringify({
              elementTypeWorkloads: this.elementTypeWorkloads,
            }),
          },
        });

        await this.$store.dispatch(
          'dhmeProductionPlanningStore/importProductionTasks',
          tasks
        );
        this.generatingPlanning = false;
        this.closePlanner();
      }
    },
  },
};
</script>

<template>
  <div class="d-flex flex-column">
    <module-navigation-bar title="Production planning">
      <template slot="module-nav-logo">
        <img
          src="https://www.daiwahousemodular.eu/wp-content/uploads/daiwa_logo.svg"
          height="40px"
          alt=""
        />
      </template>
    </module-navigation-bar>
    <v-progress-linear
      :style="{ opacity: isLoading ? 1 : 0 }"
      color="primary"
      indeterminate
      height="5"
    />
    <div class="flex-1 d-flex pa-5" style="height: 0">
      <div class="overflow-y-auto d-flex flex-column" style="width: 200px">
        <v-subheader class="justify-center">Production requests</v-subheader>
        <div
          v-if="requestedTasks.length > 0"
          class="flex-1 overflow-y-auto d-flex flex-column px-2 my-2"
        >
          <v-card
            v-for="task in requestedTasks"
            :key="task.id"
            class="mb-2"
            outlined
            :disabled="generatingPlanning"
            :color="task.id === focusedTask?.id ? 'primary' : ''"
            :dark="task.id === focusedTask?.id"
            max-width="200"
            @click="focusOnTask(task)"
          >
            <v-card-text class="d-flex align-center">
              <v-icon class="mr-2"> mdi-cube-outline</v-icon>
              <div class="mr-2 d-flex flex-column">
                <span>{{ task.task_project.name }}</span>
                <span class="font-italic fs-10">{{
                  task.task_type.custom_2
                }}</span>
                <span class="fs-12 mt-1">{{
                  formatTimestamp(task.created_at)
                }}</span>
              </div>
            </v-card-text>
          </v-card>
        </div>
        <div
          v-else
          class="font-italic d-flex align-center justify-center flex-1"
        >
          No new requests
        </div>
      </div>
      <v-card
        v-if="focusedTask"
        outlined
        :disabled="generatingPlanning"
        class="background-white d-flex flex-column overflow-y-auto"
        style="border-radius: 10px; max-width: 500px"
      >
        <v-card-text class="flex-1 d-flex flex-column flex-scroll-height">
          <v-form
            ref="productionPlanner"
            class="d-flex flex-column flex-scroll-height flex-1"
          >
            <div class="d-flex align-center">
              <ant-input label="Production Hall" class="mr-5" top-margin="mt-0">
                <template #input-field>
                  <v-select
                    v-model="selectedProductionHallId"
                    :items="productionHalls"
                    placeholder="hall"
                    dense
                    filled
                    :rules="[rules.required]"
                    item-text="title"
                    item-value="id"
                  >
                    <template #item="{ item }">
                      {{ item.title }}
                      <v-spacer />
                      <v-chip class="mx-2" small>
                        <v-icon left small
                          >{{
                            item.automated
                              ? 'mdi-robot-industrial'
                              : 'mdi-account-wrench'
                          }}
                        </v-icon>
                        {{ item.automated ? 'Automated' : 'Manual' }}
                      </v-chip>
                    </template>
                  </v-select>
                </template>
              </ant-input>

              <ant-input label="Configuration" class="mr-5" top-margin="mt-0">
                <template #input-field>
                  <v-select
                    v-model="selectedProductionConfiguration"
                    :items="configurationsByHall"
                    :disabled="!selectedProductionHallId"
                    placeholder="Configuration"
                    dense
                    filled
                    :rules="[rules.required]"
                    item-text="title"
                    item-value="id"
                  />
                </template>
              </ant-input>
            </div>
            <ant-input label="Lane" top-margin="mt-0">
              <template #input-field>
                <v-select
                  v-model="selectedProductionLane"
                  :items="configurationLocations"
                  placeholder="1"
                  item-value="assembly_lane"
                  item-text="assembly_lane"
                  :rules="[rules.required]"
                  dense
                  filled
                />
              </template>
            </ant-input>
            <div
              v-if="
                selectedProductionHallId &&
                selectedProductionConfiguration &&
                selectedProductionLane
              "
              class="d-flex flex-column flex-scroll-height flex-1"
            >
              <div class="d-flex">
                <v-btn-toggle
                  v-model="dateTab"
                  tile
                  color="primary"
                  group
                  mandatory
                >
                  <v-tooltip bottom>
                    <template #activator="{ on, attrs }">
                      <v-btn value="date" small v-bind="attrs" v-on="on">
                        Date
                      </v-btn>
                    </template>
                    <span
                      >Pick a date and time when the assembly will start in the
                      hall</span
                    >
                  </v-tooltip>
                  <v-tooltip bottom>
                    <template #activator="{ on, attrs }">
                      <v-btn value="project" small v-bind="attrs" v-on="on">
                        Project
                      </v-btn>
                    </template>
                    <span
                      >Select the project where the current planning will align
                      with at the end</span
                    >
                  </v-tooltip>
                </v-btn-toggle>
              </div>
              <div v-if="dateTab === 'date'" class="d-flex align-center">
                <ant-input label="Start current" class="mr-5">
                  <template #input-field>
                    <v-menu
                      v-model="dateMenu"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template #activator="{ on, attrs }">
                        <v-text-field
                          v-model="startDate"
                          prepend-icon="mdi-calendar"
                          readonly
                          v-bind="attrs"
                          :rules="[rules.required]"
                          placeholder="2024-01-01"
                          dense
                          filled
                          v-on="on"
                        />
                      </template>
                      <v-date-picker
                        v-model="startDate"
                        :min="now"
                        @input="dateMenu = false"
                      ></v-date-picker>
                    </v-menu>
                  </template>
                </ant-input>
                <ant-input label="Start hour" class="mr-5">
                  <template #input-field>
                    <v-text-field
                      v-model="startHour"
                      placeholder="7"
                      :rules="[rules.required]"
                      dense
                      type="number"
                      filled
                    />
                  </template>
                </ant-input>
                <ant-input label="Work hours" class="mr-5">
                  <template #input-field>
                    <v-text-field
                      v-model="workHours"
                      placeholder="8"
                      :rules="[rules.required]"
                      dense
                      type="number"
                      filled
                    />
                  </template>
                </ant-input>
              </div>
              <div v-if="dateTab === 'project'">
                <ant-input label="Project" class="mr-5">
                  <template #input-field>
                    <v-select
                      v-model="previousAlignedAssemblyPhase"
                      :items="configurationPhases"
                      placeholder="normal"
                      :rules="[rules.required]"
                      dense
                      filled
                      item-text="task_project.name"
                      return-object
                    >
                      <template #item="{ item }">
                        {{ `${item.title} (${item.task_type.custom_2})` }}
                      </template>
                    </v-select>
                  </template>
                </ant-input>
              </div>
              <div class="d-flex flex-column flex-scroll-height flex-1">
                <v-subheader class="mb-5">
                  Here, you can define how long it takes to assembly each
                  specific element type.
                </v-subheader>
                <div class="flex-1 flex-scroll-height overflow-y-auto py-2">
                  <div
                    v-for="type in focusedTaskElementTypes"
                    :key="type.id"
                    class="d-flex align-center mb-2"
                  >
                    <span class="mt-5 mr-2">
                      {{ `${countElementsByType(type.element_type)}x` }}</span
                    >
                    <v-chip class="mt-5 mr-5" small
                      >{{ type.element_type }}
                    </v-chip>
                    <ant-input
                      label="Production Workload"
                      class="mr-5 flex-grow-1"
                      top-margin="mt-0"
                    >
                      <template #input-field>
                        <v-text-field
                          v-model="elementTypeWorkloads[type.element_type]"
                          placeholder="10"
                          hide-details
                          :rules="[rules.required]"
                          dense
                          type="number"
                          filled
                          suffix="min"
                        />
                      </template>
                    </ant-input>
                  </div>
                </div>
              </div>
              <div class="d-flex align-center">
                <v-tooltip bottom>
                  <template #activator="{ on, attrs }">
                    <div v-bind="attrs" class="mr-5" v-on="on">
                      <v-icon class="mr-2">mdi-account-wrench</v-icon>
                      {{ configurationLocations.length }}
                    </div>
                  </template>
                  <span>Locations</span>
                </v-tooltip>
                <v-tooltip bottom>
                  <template #activator="{ on, attrs }">
                    <div v-bind="attrs" class="mr-5" v-on="on">
                      <v-icon class="mr-2">mdi-cube</v-icon>
                      {{ focusedTaskAssemblyTasks.length }}
                    </div>
                  </template>
                  <span>Modules</span>
                </v-tooltip>
                <v-tooltip bottom>
                  <template #activator="{ on, attrs }">
                    <div v-bind="attrs" v-on="on">
                      <v-icon class="mr-2">mdi-cube-outline</v-icon>
                      {{ focusedElements.length }}
                    </div>
                  </template>
                  <span>Elements</span>
                </v-tooltip>
                <span v-if="generatingPlanning">
                  {{ importCounter }}/{{ totalLength }}
                </span>
                <v-spacer />
                <v-btn
                  text
                  color="error"
                  class="mr-5"
                  :disabled="generatingPlanning"
                  @click="closePlanner"
                  >Cancel
                </v-btn>
                <v-btn
                  elevation="0"
                  color="primary"
                  :loading="generatingPlanning"
                  :disabled="generatingPlanning"
                  @click="planProduction"
                  >Plan
                </v-btn>
              </div>
            </div>
          </v-form>
        </v-card-text>
      </v-card>

      <div class="flex-1 d-flex flex-column ml-5" style="width: 0">
        <div class="d-flex mb-5 align-center">
          <v-chip
            v-if="focusedLane"
            close
            @click:close="
              $store.commit('dhmeProductionPlanningStore/focus_on_lane', null)
            "
            >{{ focusedLane }}</v-chip
          >
          <v-spacer />
          <v-btn-toggle
            v-model="ganttScale"
            mandatory
            color="primary"
            rounded
            dense
          >
            <v-btn v-for="option in ganttZoomLevels" :key="option.key">
              {{ option.value }}
            </v-btn>
          </v-btn-toggle>
        </div>
        <dhme-production-planning-chart ref="production-hall-gantt" />
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss"></style>
